@use '@angular/material' as mat;

@mixin theme($theme, $theme-type: 'light') {
  $primary: mat.get-theme-color($theme, primary, 50);
  $accent: mat.get-theme-color($theme, secondary, 50);
  $background: mat.get-theme-color($theme, primary, 50);

  app-playlist-list {
    $highlightSize: 6px;

    .menu-item-container {
      border-left: $highlightSize solid transparent;
      box-sizing: border-box;
      position: relative;

      &.isActive {
        border-left: $highlightSize solid rgba($primary, 0.50);
      }

      .menu-item-container {
        left: -$highlightSize;
        width: calc(100% + $highlightSize);
      }
    }

    [mat-list-item] {
      box-sizing: border-box;

      &.active {
        border-left: $highlightSize solid $accent;
        width: calc(100% + $highlightSize);
        left: -$highlightSize;
        border-radius: 0 var(--mat-list-active-indicator-shape) var(--mat-list-active-indicator-shape) 0;

        .mat-list-item-content {
          margin-left: -5px !important;
        }
      }
    }
  }
  .mat-row.selected {
    td {
      background: $background;
    }
  }
}
