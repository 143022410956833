@use "@angular/material" as mat;

@mixin theme($theme, $theme-type: 'light') {
  app-song-card {
    @if ($theme-type == 'dark') {
      background: rgba(mat.get-theme-color($theme, neutral, 10), 0.75);
    } @else {
      background: rgba(mat.get-theme-color($theme, neutral, 90), 0.75);
    }

    &.active {
      $background: mat.get-theme-color($theme, primary, 50);
      border-left: 6px solid $background;
    }
  }
}
