@use "@angular/material" as mat;

@function get-theme-foreground($theme, $hue, $theme-type: 'light') {
  @if ($theme-type == 'light') {
    @return mat.get-theme-color($theme, $hue, 90);
  } @else {
    @return mat.get-theme-color($theme, $hue, 10);
  }
}

@mixin get-theme-color($theme, $theme-type: 'light') {
  &.mat-primary {
    background: mat.get-theme-color($theme, primary, 50);
    color: get-theme-foreground($theme, primary, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, primary, $theme-type)};
  }

  &.mat-accent, &mat-secondary {
    background: mat.get-theme-color($theme, secondary, 50);
    color: get-theme-foreground($theme, secondary, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, secondary, $theme-type)};
  }

  &.mat-tertiary {
    background: mat.get-theme-color($theme, tertiary, 50);
    color: get-theme-foreground($theme, tertiary, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, tertiary, $theme-type)};
  }
  &.mat-neutral {
    background: mat.get-theme-color($theme, neutral, 50);
    color: get-theme-foreground($theme, neutral, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, neutral, $theme-type)};
  }

  &.mat-warn {
    background: mat.get-theme-color($theme, error, 50);
    color: get-theme-foreground($theme, error, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, error, $theme-type)};
  }
  &.mat-neutral-variant {
    background: mat.get-theme-color($theme, neutral-variant, 50);
    color: get-theme-foreground($theme, neutral-variant, $theme-type);
    --mat-icon-color: #{get-theme-foreground($theme, neutral-variant, $theme-type)};
  }
}

@mixin theme($theme, $theme-type: 'light') {
  mat-toolbar {
    @include get-theme-color($theme);
  }
  .mat-mdc-button-base, .mat-mdc-fab-base {
    @include get-theme-color($theme);
  }

}
