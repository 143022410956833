// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@use 'componentThemeManager';


$heading-font-family: 'Poppins', sans-serif;
$regular-font-family: 'Inter', sans-serif;
// include the typography from google
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
$my-app-typography: (
  plain-family: $regular-font-family,
  brand-family: $heading-font-family,
  use-system-variables: true,
);


// Note: Color palettes are generated from primary: #2f8acd, secondary: #528400, tertiary: #8acd2f, neutral: #7a767a
$_palettes: (
  primary: (
    0: #000000,
    10: #001d33,
    20: #003353,
    25: #003e64,
    30: #004a76,
    35: #005688,
    40: #479dce,
    50: #3da0e5,
    60: #4096da,
    70: #5fb1f7,
    80: #97cbff,
    90: #cee5ff,
    95: #e8f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #12002d,
    20: #22005c,
    25: #2b0077,
    30: #330093,
    35: #3b00ae,
    40: #4400ca,
    50: #643ae0,
    60: #825ce3,
    70: #9f7fe7,
    80: #c09fea,
    90: #e3c4ee,
    95: #f2ddf7,
    98: #f9eefb,
    99: #fbf4fd,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #102000,
    20: #1f3700,
    25: #274300,
    30: #2f4f00,
    35: #385c00,
    40: #406900,
    50: #528400,
    60: #65a100,
    70: #7bbd1d,
    80: #95da3b,
    90: #b0f756,
    95: #d2ff9a,
    98: #f0ffd7,
    99: #f9ffe8,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    4: #0c0b0c,
    6: #111012,
    10: #1d1b1e,
    12: #211f22,
    17: #2c292d,
    20: #322f33,
    22: #363337,
    24: #3b383c,
    25: #3d3a3e,
    30: #494649,
    35: #555155,
    40: #615d61,
    50: #7a767a,
    60: #948f93,
    70: #afaaae,
    80: #cac5c9,
    87: #ded9dd,
    90: #e7e1e5,
    92: #ede7eb,
    94: #f2ecf0,
    95: #f5eff3,
    96: #f8f2f6,
    98: #fef7fc,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #151c24,
    20: #2a3139,
    25: #353c45,
    30: #404750,
    35: #4c535c,
    40: #575f68,
    50: #707881,
    60: #8a919b,
    70: #a4acb6,
    80: #c0c7d2,
    90: #dce3ee,
    95: #eaf1fc,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true
  ),
  density: (scale: -2),
  typography: $my-app-typography,
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true
  ),
  density: (scale: -2),
  typography: $my-app-typography,
));

@mixin theme() {
  @include mat.core();
  @include mat.typography-hierarchy($light-theme);
  html {
    //@include mat.core-theme($light-theme);
    @debug 'light-theme';
    @include mat.all-component-themes($light-theme);
    @include componentThemeManager.theme($light-theme);
    @include mat.system-level-colors($light-theme);
    @include mat.system-level-typography($light-theme);
    @include mat.color-variants-backwards-compatibility($light-theme);

    .theme-alternate {
      @include mat.core-color($dark-theme);
      @include mat.all-component-colors($dark-theme);
      @include componentThemeManager.theme($dark-theme, dark);
      @include mat.system-level-colors($dark-theme);
      @include mat.color-variants-backwards-compatibility($dark-theme);


    }
  }
}
