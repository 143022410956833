@use "stylesm3-theme" as defaultTheme;
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

//@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

@include defaultTheme.theme();
app-main > .mat-toolbar {
  padding: 1rem;
  height: auto;
}


.song.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 9999 !important;
  display: flex;
  margin: .5rem;
  padding: .5rem;
  background: #fff;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

//.full-screen {
//  .mat-dialog-content {
//    max-height: calc(100vh - 11rem);
//    overflow: hidden;
//  }
//}
